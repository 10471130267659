//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports
import { Card, CardContent, CardMedia, CircularProgress, Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

// Custom components imports (and hooks and helpers)
import { SerializedError, current } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
    VTButton,
    VTCard,
    VTTypography,
    useGetMuiBreakPointLabel,
    useOnWindowResize,
} from "@virtus-tech-repository/virtus-tech-repository";

import VTSkeletonCard from "./VTSkeletonCard";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { blankScenarioOverview } from "../../helpers/scenarioDataGenerator";
import NoScenarios from "../../assets/images/NoScenarios.svg";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import { ContentCopyRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import { MouseEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { ExposedVTCardRefMethodsObject } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCard";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useGetAllGroupsQuery } from "../../services/users.service";
import { v4 as uuid } from "uuid";
import { ERole, IGroup } from "../../models/user.model";
import { IScenarioOverview } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import {
    useCreateScenarioMutation,
    useDeleteScenarioMutation,
    useDuplicateScenarioMutation,
    useGetScenariosQuery,
    useUpdateScenarioMutation,
} from "../../services/scenario.service";
// import { useGetBreakPointLabel } from "../../hooks/useGetBreakpointLabel";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    scenarioElement: IScenarioOverview;
    scenarioIndex: number;
    setScenarioDialog: any;
    duplicateScenario: any;
    userId: any;
    setScenarioLoading: any;
    deleteScenario: any;
    scenarios: any;
    filterValue: any;
    searchValue: any;
    scenarioLoading: any;
    isAboveMdScreenWidth: any;
    createScenario: any;
    navigate: any;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeCardGridElement({
    scenarioElement,
    scenarioIndex,
    setScenarioDialog,
    duplicateScenario,
    userId,
    setScenarioLoading,
    deleteScenario,
    filterValue,
    isAboveMdScreenWidth,
    searchValue,
    createScenario,
    scenarios,
    scenarioLoading,
    navigate,
}: IProps) {
    const dispatch = useAppDispatch();
    const vtCardRef = useRef<ExposedVTCardRefMethodsObject>(null);
    let menuOptions = [
        {
            component: (
                <VTButton
                    type={"list"}
                    startIcon={<EditRounded />}
                    onClick={() => {
                        setScenarioDialog(scenarioElement);
                        dispatch(
                            setCurrentSidePanel({
                                currentTab: undefined,
                                currentWidth: 0,
                                option: undefined,
                            }),
                        );
                        vtCardRef.current?.closePopUpOptionsMenu();
                    }}
                >
                    Edit
                </VTButton>
            ),
        },
        {
            component: (
                <VTButton
                    type={"list"}
                    startIcon={<ContentCopyRounded />}
                    onClick={() => {
                        duplicateScenario({
                            userId: userId,
                            scenarioId: scenarioElement.id,
                        });
                        vtCardRef.current?.closePopUpOptionsMenu();
                    }}
                >
                    Duplicate
                </VTButton>
            ),
        },
    ];

    if (scenarioElement.owner === userId) {
        menuOptions.push({
            component: (
                <VTButton
                    type={"list"}
                    startIcon={<DeleteRounded />}
                    onClick={() => {
                        if (window.confirm("Are you sure you want to delete this item?")) {
                            setScenarioLoading(scenarioElement.id);
                            deleteScenario(scenarioElement.id);
                            vtCardRef.current?.closePopUpOptionsMenu();
                        }
                    }}
                >
                    Delete
                </VTButton>
            ),
        });
    }

    useEffect(() => {
        console.log("Card data", scenarioElement);
    }, []);

    return (
        <Grid
            item
            xs={12}
            md={6}
            lg={4}
            xl={3}
            // container
            sx={{}}
        >
            <VTCard
                ref={vtCardRef}
                sx={
                    scenarios.filter(
                        (filterElement: IScenarioOverview) =>
                            (filterValue === "All Scenarios" || filterElement.owner === userId) &&
                            filterElement.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
                    ).length ===
                    scenarioIndex + 1
                        ? {
                              width: "100%",
                              maxWidth: "none",
                              height: `${360}px`,
                              marginBottom: "40px",
                          }
                        : {
                              width: "100%",
                              maxWidth: "none",
                              height: `${360}px`,
                          }
                }
                loading={scenarioLoading === scenarioElement.id}
                title={scenarioElement.title ? scenarioElement.title : ""}
                titleCutOffPoint={isAboveMdScreenWidth ? 17 : 13}
                textCutOffPoint={25}
                text={scenarioElement.description ? scenarioElement.description : ""}
                onWholeCardClicked={() => {
                    createScenario({
                        ...scenarioElement,
                        date: new Date(),
                        scenes: [],
                    });

                    navigate(`/creator/${scenarioElement.id}`);
                }}
                mediaType={{
                    imageUrl: scenarioElement.previewImage ? scenarioElement.previewImage : ``,
                }}
                menuOptions={{
                    menuOptionsList: menuOptions,
                }}
                publishedVersion={
                    //@ts-ignore
                    scenarioElement.published &&
                    scenarioElement.published.published &&
                    scenarioElement.published.currentPublishedId
                        ? //@ts-ignore
                          scenarioElement.published.publishedArchive.findIndex(
                              (findElement: any) =>
                                  //@ts-ignore
                                  findElement.publishedId === scenarioElement.published.currentPublishedId,
                          ) + 1
                        : undefined
                }
            />
        </Grid>
    );
}

import { useEffect, useRef, useState } from "react";
import { SxProps } from "@mui/system";
import * as THREE from "three";

import { Canvas, useThree } from "@react-three/fiber";
import { ReactNode } from "react";

import { Box, Svg } from "@react-three/drei";
import { Grid } from "@mui/material";
import { VTButton, VTCanvas, VTCube, VTDialog, VTVideoControls } from "@virtus-tech-repository/virtus-tech-repository";
import { iImageProps } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCube";
import { useDispatch } from "react-redux";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useAppSelector } from "../../store/hooks";
import VTSphere from "./VTSphere";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

export interface ILocationProps {
    currentScene: IScene;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (sceneId: string, newPosition: THREE.Vector3) => void;
    sx?: SxProps;
    children?: ReactNode;
}

export default function VTLocation({ currentScene, open, setOpen, onSave, sx, children }: ILocationProps) {
    const svgRef = useRef<any>(null);
    const { organisation } = useAppSelector((state) => state.userReducer);

    const dispatch = useDispatch();

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );

    const [currentImageTextures, setCurrentImageTextures] = useState<iImageProps | undefined>(undefined);
    useEffect(() => {
        if (currentScene.mediaType === EDraggableOptions.IMMERSIVE_IMAGE) {
            const loader = new THREE.TextureLoader();

            // @ts-ignore
            const backTexture = loader.load(currentScene.src + "/b.jpg");
            backTexture.colorSpace = THREE.SRGBColorSpace;

            // @ts-ignore
            const frontTexture = loader.load(currentScene.src + "/f.jpg");
            frontTexture.colorSpace = THREE.SRGBColorSpace;

            // @ts-ignore
            const leftTexture = loader.load(currentScene.src + "/l.jpg");
            leftTexture.colorSpace = THREE.SRGBColorSpace;

            // @ts-ignore
            const rightTexture = loader.load(currentScene.src + "/r.jpg");
            rightTexture.colorSpace = THREE.SRGBColorSpace;

            // @ts-ignore
            const upTexture = loader.load(currentScene.src + "/u.jpg");
            upTexture.colorSpace = THREE.SRGBColorSpace;

            // @ts-ignore
            const downTexture = loader.load(currentScene.src + "/d.jpg");
            downTexture.colorSpace = THREE.SRGBColorSpace;

            setCurrentImageTextures({
                left: leftTexture,
                right: rightTexture,
                up: upTexture,
                down: downTexture,
                front: frontTexture,
                back: backTexture,
            });
        }
    }, [currentScene.src]);

    return (
        <>
            <VTDialog
                title={"Set Start Location"}
                open={open}
                handleClose={() => {
                    setOpen(false);
                    dispatch(setCurrentSidePanel({ currentTab: undefined, currentWidth: 0, option: undefined }));
                }}
                sx={{ marginTop: "75px", marginLeft: "100px" }}
                maxWidth={"md"}
            >
                <VTCanvas
                    style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "calc(100vh - 350px)",
                        marginTop: "20px",
                        borderRadius: "15px",
                    }}
                    debug={false}
                    attachToCameraRef={svgRef}
                    distanceFromCamera={1}
                >
                    {currentScene.mediaType === EDraggableOptions.IMMERSIVE_IMAGE && (
                        <VTCube image={currentImageTextures} />
                    )}

                    {currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                        <VTSphere
                            video={video}
                            setVideo={setVideo}
                            videoSrc={currentScene.src}
                            setVideoCurrentTime={setVideoCurrentTime}
                        />
                    )}

                    {/* PLACE THE SVG HERE */}

                    <group ref={svgRef}>
                        <Svg src={`${process.env.PUBLIC_URL}/target.svg`} position={[0, 10, -70]} scale={0.3} />
                    </group>
                </VTCanvas>

                <Grid item container xs={12} justifyContent="flex-end" padding={2} spacing={2}>
                    <Grid item>
                        <VTButton
                            color="secondary"
                            onClick={() => {
                                dispatch(
                                    setCurrentSidePanel({ currentTab: undefined, currentWidth: 0, option: undefined }),
                                );
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </VTButton>
                    </Grid>
                    <Grid item>
                        <VTButton
                            color="primary"
                            onClick={() => {
                                if (svgRef && svgRef.current && svgRef.current.position) {
                                    onSave(currentScene.id, svgRef.current.position);
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: undefined,
                                            currentWidth: 0,
                                            option: undefined,
                                        }),
                                    );
                                    setOpen(false);
                                }
                            }}
                        >
                            Save
                        </VTButton>
                    </Grid>
                </Grid>
            </VTDialog>
        </>
    );
}

import { v4 as uuid } from "uuid";
import { Vector3 } from "three";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { getImageDimensionsBeforeDrop } from "@virtus-tech-repository/virtus-tech-repository";
import { getVideoDimensionsBeforeDrop } from "@virtus-tech-repository/virtus-tech-repository/lib/utils/getContentDimensionsBeforeDropForRichTextEditorPresentationMode";

//---------------------------------------------------------------------------------------------------
// Component to create hotspot Json data
export async function blankHotspot(hotspotType: EDraggableOptions, hotspotContext?: string) {
    //-----------------------------------------------------------------------------------------------
    // Info hotspot data
    if (hotspotType === EDraggableOptions.INFORMATION) {
        return {
            id: uuid(),
            location: new Vector3(0, 0, 90),
            type: EDraggableOptions.INFORMATION,
            contents: {
                preClickContent: {
                    icon: "Info",
                    title: "Info Hotspot",
                },
                postClickContent: {
                    contentData: [
                        {
                            id: uuid(),
                            positionData: {
                                width: 100,
                                top: 12,
                                left: 33,
                                height: 24,
                            },
                            type: "html",
                            content: '<div class="rte-container"> <div>Info content</div> </div>',
                        },
                    ],
                    presentationModeContentWidthHeight: { h: 48, w: 150 },
                },
            },
            actions: {
                closeable: true,
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // Movement hotspot data
    else if (hotspotType === EDraggableOptions.MOVEMENT) {
        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.ANSWER,
            contents: {
                preClickContent: {
                    icon: "Movement",
                },
            },
            actions: {
                movement: {
                    sceneId: "Scene Undefined",
                },
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // text hotspot data
    else if (hotspotType === EDraggableOptions.TEXT_HOTSPOT) {
        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.TEXT_HOTSPOT,
            contents: {
                preClickContent: {
                    contentData: [
                        {
                            id: uuid(),
                            positionData: {
                                width: 100,
                                top: 14,
                                left: 20,
                                height: 24,
                            },
                            type: "html",
                            content: "Paragraph",
                        },
                    ],
                    presentationModeContentWidthHeight: { h: 52, w: 120 },
                },
            },
        } as IHotspot;
    }
    // Immersive model hotspot data
    else if (hotspotType === EDraggableOptions.MODEL_3D) {
        return {
            id: uuid(),
            location: new Vector3(0, -2, 2).multiplyScalar(100),
            type: EDraggableOptions.MODEL_3D,
            contents: {
                preClickContent: {
                    contentData: hotspotContext,
                },
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // Bullet point text hotspot data
    else if (hotspotType === EDraggableOptions.BULLET_POINTS_HOTSPOT) {
        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.BULLET_POINTS_HOTSPOT,
            contents: {
                preClickContent: {
                    contentData: [
                        {
                            id: uuid(),
                            positionData: {
                                width: 127,
                                top: 8.866668701171875,
                                left: 1.5,
                                height: 106.25,
                            },
                            type: "html",
                            content:
                                "<ul><li>bullet a</li><li>bullet b</li><li>bullet c&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</li></ul>",
                        },
                    ],
                    presentationModeContentWidthHeight: { h: 124, w: 130 },
                },
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // Immersive model hotspot data
    else if (hotspotType === EDraggableOptions.IMMERSIVE_MODEL) {
        return {
            id: uuid(),
            location: new Vector3(0, -2, 2),
            type: EDraggableOptions.IMMERSIVE_MODEL,
            contents: {
                preClickContent: {
                    contentData: hotspotContext,
                },
            },
            actions: {
                movement: {
                    sceneId: "Scene Undefined",
                },
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // interface model hotspot data
    else if (hotspotType === EDraggableOptions.INTERFACE) {
        return {
            id: uuid(),
            location: new Vector3(0, 0, 0),
            type: EDraggableOptions.INTERFACE,
            contents: {
                preClickContent: {
                    title: "New User Interface",
                    userInterface: [
                        {
                            sectionTitle: "New User Input",
                        },
                    ],
                },
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // complete scenario model hotspot data
    else if (hotspotType === EDraggableOptions.COMPLETE) {
        return {
            id: uuid(),
            location: new Vector3(-90, 0, 0),
            type: EDraggableOptions.ANSWER,

            contents: {
                preClickContent: {
                    title: "Complete Scenario",
                },
            },
            actions: {
                navigate: "/",
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // Proceed video model hotspot data
    else if (hotspotType === EDraggableOptions.PROCEED) {
        return {
            id: uuid(),
            location: new Vector3(-90, 0, 0),
            type: EDraggableOptions.ANSWER,

            contents: {
                preClickContent: {
                    title: "Proceed",
                },
                postClickContent: {
                    title: "Proceeding...",
                },
            },
            actions: {
                appFeedback: {
                    proceed: true,
                    delay: 2,
                },

                closeable: false,
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // Image model hotspot data
    else if (hotspotType === EDraggableOptions.IMAGE) {
        const imgDimensions = await getImageDimensionsBeforeDrop(hotspotContext!, 240);
        const imageScale = 1;
        const imagePadding = 10;

        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.INFORMATION,
            contents: {
                preClickContent: {
                    presentationModeContentWidthHeight: {
                        h: imgDimensions.height * imageScale + imagePadding * 2,
                        w: imgDimensions.width * imageScale + imagePadding * 2,
                    },
                    contentData: [
                        {
                            id: uuid(),
                            positionData: {
                                width: imgDimensions.width * imageScale,
                                top: imagePadding,
                                left: imagePadding,
                                height: imgDimensions.height * imageScale,
                            },
                            type: "images",
                            content: hotspotContext,
                        },
                    ],
                },
            },
        } as IHotspot;
    }
    //-----------------------------------------------------------------------------------------------
    // Video model hotspot data
    else if (hotspotType === EDraggableOptions.VIDEO) {
        const videoURL =
            hotspotContext!.split(".mp4")[0] + "/" + hotspotContext!.split(".mp4")[0].split("video/")[1] + ".mp4";
        const videoScale = 1.5;
        const videoPadding = 10;
        try {
            const vidDimensions = await getVideoDimensionsBeforeDrop(videoURL);
            return {
                id: uuid(),
                location: new Vector3(90, -10, 0),
                type: EDraggableOptions.INFORMATION,
                contents: {
                    preClickContent: {
                        presentationModeContentWidthHeight: {
                            h: vidDimensions.height * videoScale + videoPadding * 2,
                            w: vidDimensions.width * videoScale + videoPadding * 2,
                        },
                        contentData: [
                            {
                                id: uuid(),
                                positionData: {
                                    width: vidDimensions.width * videoScale,
                                    top: videoPadding,
                                    left: videoPadding,
                                    height: vidDimensions.height * videoScale,
                                },
                                type: "video",
                                content: videoURL,
                            },
                        ],
                    },
                },
            } as IHotspot;
        } catch {
            console.error("Could not get generate size of video");
            return {
                id: uuid(),
                location: new Vector3(90, -10, 0),
                type: EDraggableOptions.INFORMATION,
                contents: {
                    preClickContent: {
                        presentationModeContentWidthHeight: {
                            h: 160 * videoScale + videoPadding * 2,
                            w: 200 * videoScale + videoPadding * 2,
                        },
                        contentData: [
                            {
                                id: uuid(),
                                positionData: {
                                    width: 200 * videoScale,
                                    top: videoPadding,
                                    left: videoPadding,
                                    height: 160 * videoScale,
                                },
                                type: "video",
                                content: videoURL,
                            },
                        ],
                    },
                },
            } as IHotspot;
        }
    }
    //-----------------------------------------------------------------------------------------------
    // Answer model hotspot data
    else if (hotspotType === EDraggableOptions.ANSWER) {
        if (hotspotContext === "correct") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: { colours: { background: { r: 48, g: 48, b: 48, a: 1 } } },
                    postClickStyles: {
                        colours: { background: { r: 178, g: 222, b: 39, a: 1 } },
                        hover: { colours: { background: { r: 158, g: 202, b: 19, a: 1 } }, scale: 1.2 },
                    },
                },
                contents: {
                    preClickContent: {
                        title: "Correct Answer Hotspot",
                    },
                    postClickContent: {
                        title: "Correct Answer Hotspot Feedback",
                    },
                },
                actions: {
                    appFeedback: {
                        proceed: true,
                        delay: 3,
                    },

                    closeable: false,
                },
            } as IHotspot;
        } else if (hotspotContext === "incorrect") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: { colours: { background: { r: 48, g: 48, b: 48, a: 1 } } },
                    postClickStyles: {
                        colours: { background: { r: 255, g: 0, b: 0, a: 1 } },
                        hover: { colours: { background: { r: 235, g: 0, b: 0, a: 1 } }, scale: 1.2 },
                    },
                },
                contents: {
                    preClickContent: {
                        title: "Incorrect Answer Hotspot",
                    },
                    postClickContent: {
                        title: "Incorrect Answer Hotspot Feedback",
                    },
                },
                actions: {
                    closeable: false,
                },
            } as IHotspot;
        }
        //-----------------------------------------------------------------------------------------------
        // Invisible hotspot model hotspot data
        else if (hotspotContext === "invisible") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: {
                        colours: { background: { r: 0, g: 0, b: 0, a: 0 }, text: { r: 0, g: 0, b: 0, a: 0 } },
                    },
                },
                contents: {
                    preClickContent: {
                        title: " ",
                    },
                    postClickContent: {
                        title: "Hotspot feedback",
                    },
                },
                actions: {
                    closeable: false,
                },
            } as IHotspot;
        }
        //-----------------------------------------------------------------------------------------------
        // continue video model hotspot data
        else if (hotspotContext === "continue") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: {
                        colours: { background: { r: 65, g: 117, b: 5, a: 1 } },
                        hover: { colours: { background: { r: 65, g: 117, b: 5, a: 1 } } },
                    },
                    postClickStyles: {
                        colours: { background: { r: 65, g: 117, b: 5, a: 1 } },
                        hover: { colours: { background: { r: 65, g: 117, b: 5, a: 1 } } },
                    },
                },
                contents: {
                    preClickContent: {
                        title: "Continue",
                    },
                    postClickContent: {
                        title: "Restarting ...",
                    },
                },
                actions: {
                    appFeedback: {
                        proceed: true,
                        delay: 2,
                    },

                    closeable: false,
                },
            } as IHotspot;
        }
        //-----------------------------------------------------------------------------------------------
        // Blank answer as fallback data model
        else {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,

                contents: {
                    preClickContent: {
                        title: "Answer Hotspot",
                    },
                    postClickContent: {
                        title: "Answer Feedback",
                    },
                },
                actions: {
                    closeable: false,
                },
            } as IHotspot;
        }
    } else {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(0, 0, -5),
            type: EDraggableOptions.INFORMATION,
            styles: {},
            contents: {
                preClickContent: {
                    title: "Hotspot",
                },
            },
            actions: {
                closeable: true,
            },
        } as IHotspot;
    }
}

//-----------------------------------------------------------------------------------------------
// Question Json data
export function blankQuestionHotspot(time: number): IHotspot {
    const newHotspot: IHotspot = {
        id: uuid(),
        location: new Vector3(0, 0, 0),
        type: EDraggableOptions.QUESTION,
        contents: {
            preClickContent: {
                title: "",
                question: {
                    time: time,
                    answers: [],
                },
            },
        },
    } as IHotspot;

    return newHotspot;
}

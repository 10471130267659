export enum EOrganisation {
    DEFAULT = "default",
    VIRTUS_TECH = "virtus-tech",
    CARDIFF_UNI = "cardiff-uni",
    NEW_COLLAGE = "new-collage",
    WREXHAM_UNI = "wrexham-uni",
    CARDIFF_MET_UNI = "cardiff-met-uni",
    ZURICH_UK = "zurich-uk",
    SWANSEA_UNI = "swansea_uni",
    ECO_GOAL = "eco-goal",
    NOVA = "nova",
    GENER8 = "gener8",
}

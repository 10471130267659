/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 movement.glb -t
*/

import * as THREE from "three";
import { useEffect, useRef, useState, Suspense } from "react";
import { Html, OrbitControls, PivotControls, useGLTF } from "@react-three/drei";
import VTHotspotControls from "@virtus-tech-repository/virtus-tech-repository/lib/components/VtHotspotControls";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { Params, useParams } from "react-router-dom";
import { useDeleteHotspotMutation, useUpdateHotspotMutation } from "../../../services/hotspot.service";
import {
    setCurrentAttachedHotspot,
    setCurrentlyDraggingThreeDModel,
    setCurrentSidePanel,
    setHotspotAdded,
} from "../../../store/slices/current.slice";
import { useThree } from "@react-three/fiber";

interface IProps {
    currentAttachedObject: any;
    setCurrentAttachedObject: any;
    hotspot: any;
    setCurrentHotspot: any;
    maxPixelSize?: number;
}

export default function ImmersiveModel({
    currentAttachedObject,
    setCurrentAttachedObject,
    hotspot,
    setCurrentHotspot,
    maxPixelSize = 100,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const [updateHotspot] = useUpdateHotspotMutation();
    const [deleteHotspot] = useDeleteHotspotMutation();
    const ref: any = useRef<any | null>();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const {
        currentAttachedHotspot,
        currentHotspot,
        currentlyPreview,
        hotspotAdded,
        currentQuestion,
        currentScene,
        currentScenario,
    } = useAppSelector((state) => state.currentReducer);
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [moving, setMoving] = useState<boolean>(false);
    const [localPosition, setLocalPosition] = useState<THREE.Vector3>();
    const objectRef = useRef<any>();
    //@ts-ignore
    const { scene } = useGLTF(hotspot.contents.preClickContent.contentData);
    const { camera, size: canvasSize } = useThree();
    const [isDragging, setIsDragging] = useState(false);
    const [scale, setScale] = useState(1);
    const [previousMousePosition, setPreviousMousePosition] = useState({ x: 0, y: 0 });
    const touchStartDistance = useRef<number | null>(null);
    const initialScale = useRef<number>(1);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (currentAttachedHotspot && currentAttachedHotspot.id && currentAttachedHotspot.id === hotspot.id) {
            setCurrentAttachedObject(ref);

            // is this line of code here doing anything?
            dispatch(setCurrentAttachedHotspot({} as IHotspot));
        }
    }, [currentAttachedHotspot]);

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const resizeObjectUsingBoundingBox = () => {
            if (!objectRef.current) return;

            // Create the bounding box from the object
            const boundingBox = new THREE.Box3().setFromObject(objectRef.current);
            const size = new THREE.Vector3();
            boundingBox.getSize(size); // size will now have x, y, z dimensions of the object

            // Find the largest dimension (x, y, or z)
            const maxDimension = Math.max(size.x, size.y, size.z);

            // If the max dimension is 0, we can't scale, so return early
            if (maxDimension === 0) return;

            // Calculate the scale factor to bring the largest dimension to 1 unit
            const scaleFactor = 100 / maxDimension;

            // Apply the scale uniformly to the object
            objectRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor);
            console.log("Scaling object by factor: ", scaleFactor);
        };

        setIsReady(true);
        resizeObjectUsingBoundingBox();

        window.addEventListener("resize", resizeObjectUsingBoundingBox);

        return () => {
            window.removeEventListener("resize", resizeObjectUsingBoundingBox);
        };
    }, [hotspot.contents.preClickContent.contentData]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    const handleMouseDown = (event: any) => {
        setIsDragging(true);
        setPreviousMousePosition({ x: event.clientX, y: event.clientY });
        dispatch(setCurrentlyDraggingThreeDModel(true));
    };

    const handleMouseMove = (event: any) => {
        if (isDragging && objectRef.current) {
            const deltaX = event.clientX - previousMousePosition.x;
            const deltaY = event.clientY - previousMousePosition.y;

            const rotationSpeed = 0.01;
            objectRef.current.rotation.y += deltaX * rotationSpeed;
            objectRef.current.rotation.x += deltaY * rotationSpeed;

            setPreviousMousePosition({ x: event.clientX, y: event.clientY });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        dispatch(setCurrentlyDraggingThreeDModel(false));
    };

    // Pinch to zoom on touchscreens

    const handleTouchStart = (event: any) => {
        console.log("touch starting: ", event.touches);
        if (event.touches.length === 1) {
            // Single touch for rotation
            setIsDragging(true);
            setPreviousMousePosition({
                x: event.touches[0].clientX,
                y: event.touches[0].clientY,
            });
            dispatch(setCurrentlyDraggingThreeDModel(true));
        } else if (event.touches.length === 2) {
            // Pinch to zoom - store initial distance between touches
            const dx = event.touches[0].clientX - event.touches[1].clientX;
            const dy = event.touches[0].clientY - event.touches[1].clientY;
            touchStartDistance.current = Math.sqrt(dx * dx + dy * dy);
            initialScale.current = scale;
        }
    };

    const handleTouchMove = (event: any) => {
        if (event.touches.length === 1 && isDragging && objectRef.current) {
            // Handle rotation
            const touch = event.touches[0];
            const deltaX = touch.clientX - previousMousePosition.x;
            const deltaY = touch.clientY - previousMousePosition.y;

            const rotationSpeed = 0.01;
            objectRef.current.rotation.y += deltaX * rotationSpeed;
            objectRef.current.rotation.x += deltaY * rotationSpeed;

            setPreviousMousePosition({
                x: touch.clientX,
                y: touch.clientY,
            });
        } else if (event.touches.length === 2 && touchStartDistance.current !== null && objectRef.current) {
            // Handle pinch zoom
            const dx = event.touches[0].clientX - event.touches[1].clientX;
            const dy = event.touches[0].clientY - event.touches[1].clientY;
            const currentDistance = Math.sqrt(dx * dx + dy * dy);

            // Calculate scale factor based on the change in distance
            const scaleFactor = currentDistance / touchStartDistance.current;
            const newScale = initialScale.current * scaleFactor;

            // Limit the minimum and maximum scale
            const minScale = 0.5;
            const maxScale = 2.0;
            const clampedScale = Math.min(Math.max(newScale, minScale), maxScale);

            setScale(clampedScale);
            objectRef.current.scale.set(clampedScale, clampedScale, clampedScale);
        }
    };

    const handleTouchEnd = (event: any) => {
        if (event.touches.length < 2) {
            // Reset pinch zoom references when fingers are lifted
            touchStartDistance.current = null;
            initialScale.current = scale;
        }
        if (event.touches.length === 0) {
            // Reset rotation when all fingers are lifted
            setIsDragging(false);
            dispatch(setCurrentlyDraggingThreeDModel(false));
        }
    };

    // Update your useEffect for event listeners:
    useEffect(() => {
        const element = objectRef.current;

        if (element) {
            const preventDefault = (e: Event) => e.preventDefault();

            element.addEventListener("touchstart", handleTouchStart, { passive: false });
            window.addEventListener("touchmove", handleTouchMove, { passive: false });
            window.addEventListener("touchend", handleTouchEnd);
            // Prevent default touch behaviors
            element.addEventListener("touchmove", preventDefault, { passive: false });

            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);

            return () => {
                element.removeEventListener("touchstart", handleTouchStart);
                window.removeEventListener("touchmove", handleTouchMove);
                window.removeEventListener("touchend", handleTouchEnd);
                element.removeEventListener("touchmove", preventDefault);
                window.removeEventListener("mousemove", handleMouseMove);
                window.removeEventListener("mouseup", handleMouseUp);
            };
        }
    }, [isDragging, previousMousePosition, scale]);

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <group ref={ref} position={[hotspot.location.x, hotspot.location.y, hotspot.location.z]}>
            {/* <PivotControls
                disableAxes={!moving}
                disableRotations={!moving}
                disableSliders={!moving}
                scale={hotspot.styles && hotspot.styles.preClickStyles.scale ? hotspot.styles.preClickStyles.scale : 1}
                onDrag={(l, dl, w, dw) => {
                    // Extract the position and rotation
                    const position = new THREE.Vector3();
                    const rotation = new THREE.Quaternion();
                    // I'm never sure whether to grab "l" or "w" here... sorry
                    w.decompose(position, rotation, new THREE.Vector3());
                    setLocalPosition(position);
                }}
            > */}
            <group dispose={null} scale={1}>
                {!currentlyPreview && (
                    <Html zIndexRange={[1, 0]}>
                        <VTHotspotControls
                            handleEditHotspotLocation={() => setCurrentAttachedObject(ref)}
                            handleSaveHotspotLocation={() => {
                                // setHotspotLoading(`hotspotMove${hotspot.id}`);
                                if (hotspotAdded && hotspotAdded.id === hotspot.id) {
                                    dispatch(setHotspotAdded(undefined));
                                }

                                if (currentAttachedObject && currentAttachedObject.current) {
                                    if (
                                        currentHotspot &&
                                        currentHotspot.currentHotspot &&
                                        currentHotspot.currentHotspot.id === hotspot.id
                                    ) {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: {
                                                    ...currentHotspot.currentHotspot,
                                                    location: currentAttachedObject.current.position,
                                                },
                                                open: false,
                                            }),
                                        );
                                    }

                                    updateHotspot({
                                        id: hotspot.id,
                                        location: currentAttachedObject.current.position,
                                    });
                                }
                                setCurrentAttachedObject(undefined);
                            }}
                            handleCancelHotspotLocation={
                                hotspotAdded && hotspotAdded.id === hotspot.id
                                    ? () => {
                                          deleteHotspot({ sceneId: sceneId!, hotspotId: hotspot.id });

                                          dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));
                                          setCurrentAttachedObject(undefined);
                                      }
                                    : () => setCurrentAttachedObject(undefined)
                            }
                            handleDeleteHotspotLocation={() => {
                                if (window.confirm("Are you sure you want to delete this hotspot?")) {
                                    deleteHotspot({ sceneId: sceneId!, hotspotId: hotspot.id });

                                    dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));
                                }
                            }}
                            handleEditHotspot={() => {
                                if (currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspot.id) {
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: undefined,
                                            currentWidth: 0,
                                            option: undefined,
                                        }),
                                    );
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: hotspot,
                                            open: true,
                                        }),
                                    );
                                } else {
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: hotspot,
                                            open: false,
                                        }),
                                    );
                                }
                            }}
                            moving={currentAttachedObject === ref}
                        />
                    </Html>
                )}

                <Suspense fallback={null}>
                    <primitive
                        ref={objectRef}
                        object={scene}
                        scale={scale}
                        position={[0, 0, 0]}
                        onPointerDown={handleMouseDown}
                    />
                </Suspense>
                <mesh
                    geometry={new THREE.SphereGeometry(10, 10, 10)}
                    material={
                        new THREE.MeshBasicMaterial({
                            // color: 0xaaaaaa,
                            // wireframe: true,
                            visible: false,
                        })
                    }
                    position={[0, 0, 0]}
                    scale={5}
                    onPointerDown={handleMouseDown}
                />
            </group>
            {/* </PivotControls> */}
        </group>
    );
}
